import { FC } from 'react';

import { FaqViewProps } from './Faq.props';

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: { category: string; items: FAQItem[] }[] = [
  {
    category: 'Platform & Usage',
    items: [
      {
        question: 'What is our company all about?',
        answer:
          'We are focused on making it faster and easier to rent from people nearby. From tools & Equipment, camping, events, kids gear, and more we know that someone nearby probably owns your need nearby. Our goal is to connect those people and make a secure platform to interact on.',
      },
      {
        question: 'What kind of items can I rent or list',
        answer:
          "Within reason, almost anything you can think of. See our policy section for specific products we aren't allowed to host on the platform but anything that could be used short term instead of buying. We even have owners excited to list things like high end Lego sets and puzzles.",
      },
      {
        question: 'How do I get started?',
        answer:
          "Account setup is simple, just fill in some basic information and you're up and running. To become an owner, we have a bit of regulatory information to collect and a Stripe account so we can get you paid.",
      },
      {
        question: 'When is the rental due?',
        answer:
          'Rental days are based on a 24 hour period. The renter and owner can communicate on flexible pick up and drop off times that are convenient for both parties. Time ranges coming soon to the date selector.',
      },
      {
        question: 'What if the rental is returned late?',
        answer:
          'In the future the platform will allow for extension requests. Running a bit behind? Be sure to keep the owner or renter in the loop. For longer timeframes consider renting the item for an additional day.',
      },
    ],
  },
  {
    category: 'Payment, Fees, and Policies',
    items: [
      {
        question: 'What type of fees do you charge?',
        answer:
          'We charge a 15% transaction fee which currently includes local taxes and credit card processing. This helps ensure both parties can use the platform',
      },
      {
        question: 'How are payments processed?',
        answer:
          'Our trusted partner Stripe securely handles all payment processing. https://docs.stripe.com/security',
      },
      {
        question: "What's the cancellation and refund policy?",
        answer:
          "We know that 99 out of 100 times our customers have seamless transactions. If for any reason you aren't satisfied with your rental please reach out to us for assistance.",
      },
      {
        question: 'When will I receive my payment after a rental?',
        answer:
          "New owners will receive payouts within 2 weeks. The longer and more active you're on the platform, the quicker your payouts will be.",
      },
    ],
  },
  {
    category: 'Trust & Safety',
    items: [
      {
        question: 'What kind of liability coverage is offered?',
        answer:
          "Renters are encouraged to treat your item as if it were their own and return it in the same condition as it was given to them. Our Rapid Rentals Protection Plan (RRPP) covers the owner's item up to $1,000 in cases of loss or damage. The best part is for the first few months this coverage is included at no additional charge. Claims are subject to review by the Rapid Rentals team.",
      },
      {
        question: 'How are users and items verified?',
        answer:
          'Stripe securely handles identity verification for our renters and owners either via entering a state ID number OR photo verification of the ID. This is matched to account and payment information.\n\nItems are encouraged to be in new working order and have 3 clear photos that show their condition.',
      },
      {
        question: 'What happens if an item is damaged, lost, or misused?',
        answer:
          "Owners are able to list any restrictions on how their items should or shouldn't be used, and the renter is responsible for following those guidelines. For accidental damage or loss, the renter can ensure they're covered by purchasing the Rapid Rentals Protection Plan at rental. Without this, the renter would be responsible for reimbursing the owner's damage or loss. Any issues can be mediated by the Rapid Rentals team.",
      },
      {
        question: 'How does the review and rating system work?',
        answer:
          "Each transaction offers 2 reviews – both the person and the item. You're able to give your feedback which helps others know the quality of your experience.\nBadges are coming soon which will show you how experienced a user is and fun things you can earn and add to your account.",
      },
    ],
  },
  {
    category: 'Privacy & Data Security',
    items: [
      {
        question: 'How is my personal and payment information secured?',
        answer:
          'Payment information is securely handled by our partner Stripe. Users can enable the optional Link service to autofill payments, or fill out credit card information each time. For more information https://docs.stripe.com/security',
      },
      {
        question:
          'Are there any legal or regulatory requirements I should be aware of?',
        answer:
          "For owners, Stripe is required to collect additional identity verification information to comply with federal Know Your Customer (KYC) laws. 1099's will be issued to owner's who we pay more than $600 in the calendar year.",
      },
    ],
  },
];

const FaqView: FC<FaqViewProps> = (props) => (
  <div>
    <div className="mx-auto p-6 px-24">
      <h1 className="text-2xl font-bold text-center mb-6">FAQ</h1>
      {faqData.map((section, index) => (
        <div key={index} className="mb-6">
          <h2 className="text-xl font-semibold border-b pb-2 mb-4">
            {section.category}
          </h2>
          {section.items.map((item, idx) => (
            <div key={idx} className="mb-4 border rounded-lg overflow-hidden">
              <div className="bg-green-600 text-white font-semibold p-3">
                {item.question}
              </div>
              <div className="p-3 bg-gray-100">{item.answer}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);

export default FaqView;
