import axios from 'axios';

import { $session } from 'state/stores';

import { LoginResponse } from 'types/auth';

import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { $itemRentDetails } from 'state/stores/rent-details';
import { $stripe } from 'state/stores/stripe';
import { $user } from 'state/stores/user';

const URL = `${process.env.REACT_APP_API_URL}`;
const STORAGE_URL = `${process.env.REACT_APP_STORAGE_URL}`;

export const getApiURL = () => {
  return URL;
};

export const getStorageURL = () => {
  return STORAGE_URL;
};

const DEFAULT_HEADERS: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'ngrok-skip-browser-warning': 'true',
};

const instance = axios.create({
  baseURL: URL,
  headers: DEFAULT_HEADERS,
});

instance.interceptors.request.use((request) => {
  const tokens = $session.actions.getTokens();
  const jwtToken = tokens?.jwtToken || '';
  if (jwtToken) {
    request.headers.Authorization = `Bearer ${jwtToken}`;
  }

  return request;
});

const refreshAuthToken = async (payload: {
  username: string;
  refreshToken: string;
}) => {
  const { data } = await axios.post(`${URL}/auth/refresh-token`, payload);
  return data as LoginResponse;
};

const refreshTokenLogic = async (callbackRequest: any) => {
  try {
    if (callbackRequest.response.data.code === 'expired_token') {
      const tokens = $session.actions.getTokens();
      const username = $session.actions.getUsername() || '';

      if (!tokens) {
        return Promise.reject({
          response: {
            data: {
              message: 'Please Log in',
            },
          },
        });
      }

      const refreshToken = tokens.refreshToken;

      const data = await refreshAuthToken({
        username,
        refreshToken,
      })
        .then((data) => data)
        .catch(() => null);

      if (!data) {
        return Promise.reject({
          response: {
            data: {
              type: 'session_expired',
              message: 'Your session has expired. Please Log in again.',
            },
          },
        });
      }

      $session.actions.setSession({
        id: data.item.id,
        tokens: data.item.tokens,
        username: data.item.username,
      });

      callbackRequest.response.config.headers['Authorization'] =
        `Bearer ${data.item.tokens.jwtToken}`;

      return Promise.resolve();
    } else {
      $session.actions.clearSession();
      return Promise.resolve();
    }
  } catch (error: any) {}
};

const options = { statusCodes: [401] };

instance.interceptors.response.use(
  (response) => {
    // Any status code that lies within the range of 2xx causes this function to trigger
    return response;
  },
  (error) => {
    const { response } = error;
    if (response) {
      // Handle specific status codes
      switch (response.status) {
        case 429:
          // Handle bad request
          // console.error('Bad Request:', response.data.message);
          $session.actions.clearSession();
          $user.actions.clearUserData();
          $stripe.actions.reset();
          $itemRentDetails.actions.reset();
          setTimeout(() => {
            if (window.location.pathname !== '/login') {
              window.location.href = '/login';
            }
          }, 1000);
          error.message = 'Please try again after some time';
          return Promise.reject(error);
      }
    } else {
      // Handle errors without a response
      // console.error('Error:', error.message);
    }

    return Promise.reject(error);
  },
);

createAuthRefreshInterceptor(instance, refreshTokenLogic, options);

export const api = instance;
