import { $session } from 'state/stores/session';
import { Product } from 'types/item';
import { OnGoingRent, RentStatus } from 'types/rent';
import { UserDetail } from 'types/user';
import DateTimeZ from 'utils/dateTime';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type State = {
  rentDetails: OnGoingRent | null;
  onGoingRents: OnGoingRent[] | [];
  product: Product | null;
  rentAvailability?: RentStatus[];
  itemOwner: UserDetail | null;
  rentCreationDate?: string;
  itemId?: string;
};

const useItemRentDetailStore = create(
  persist(
    immer<State>(() => ({
      rentDetails: null,
      onGoingRents: [],
      product: null,
      rentAvailability: [],
      itemOwner: null,
    })),
    {
      name: 'RAPID-RENTALS-ITEM-RENT-DETAILS',
    },
  ),
);

const store = useItemRentDetailStore;

const selectors = {
  useItemOwner: () => store((state) => state.itemOwner),
  useRentAvailability: () => store((state) => state.rentAvailability),
  useProduct: () => store((state) => state.product),
  useRentDetails: () => store((state) => state.rentDetails),
  useOnGoingRents: () => store((state) => state.onGoingRents),
  isOwnersItem: () => {
    const state = useItemRentDetailStore.getState();
    const userId = $session.actions.getUserId();
    if (state.product?.ownerId && userId) {
      return state.product.ownerId === userId;
    }
    return false;
  },
  isItemRented: () =>
    store((state) => {
      console.log(state.rentDetails?.endDate, 'sssss');

      let endData = new DateTimeZ(state.rentDetails?.endDate);

      if (endData.isDateExpired()) return false;
      if (state.rentDetails !== null || state.onGoingRents.length > 0) {
        return true;
      }
      return false;
    }),
};

const actions = {
  setItemOwner: (itemOwner: UserDetail) => {
    const set = useItemRentDetailStore.setState;
    set((state) => {
      state.itemOwner = itemOwner;
    });
  },
  setRentAvailability: (rentAvailability: RentStatus[]) => {
    const set = useItemRentDetailStore.setState;
    set((state) => {
      state.rentAvailability = rentAvailability;
    });
  },
  setProduct: (product: Product) => {
    const set = useItemRentDetailStore.setState;
    set((state) => {
      state.product = product;
    });
  },
  setRentCreationDate: (rentCreationDate: string) => {
    const set = useItemRentDetailStore.setState;
    set((state) => {
      state.rentCreationDate = rentCreationDate;
    });
  },
  getRentCreationDate: () => {
    const state = useItemRentDetailStore.getState();
    return state.rentCreationDate;
  },
  setRentDetails: (rentDetail: OnGoingRent) => {
    const set = useItemRentDetailStore.setState;
    set((state) => {
      state.rentDetails = rentDetail;
    });
  },
  setOnGoingRents: (onGoingRents: OnGoingRent[]) => {
    const set = useItemRentDetailStore.setState;
    set((state) => {
      state.onGoingRents = onGoingRents;
    });
  },
  setItemId: (itemId: string) => {
    const set = useItemRentDetailStore.setState;
    set((state) => {
      state.itemId = itemId;
    });
  },
  getItemId: () => {
    const state = useItemRentDetailStore.getState();
    return state.itemId;
  },
  reset: () => {
    const set = useItemRentDetailStore.setState;
    set((state) => {
      state.rentDetails = null;
      state.onGoingRents = [];
      state.itemOwner = null;
      state.rentAvailability = [];
    });
  },
};

/**
 * **Setting Store**
 *
 * this store is used to store setting data
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $itemRentDetails = {
  actions,
  selectors,
};

export default $itemRentDetails;
