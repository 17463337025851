class DateTimeZ extends Date {
  constructor(date?: string | number) {
    if (date) {
      super(date);
    } else {
      super();
    }
  }

  toFormattedString() {
    const year = this.getFullYear();
    const month = String(this.getMonth() + 1).padStart(2, '0');
    const day = String(this.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  handleTimeZone(): DateTimeZ {
    if (!this) return new DateTimeZ();
    const isoDateString = this.toISOString();
    const isoStringWithoutZ = isoDateString.replace('Z', '');
    const dateTime = new Date(isoStringWithoutZ);
    return new DateTimeZ(dateTime.getTime());
  }

  differenceInDays(otherDate: Date) {
    const thisUTC = Date.UTC(
      this.getFullYear(),
      this.getMonth(),
      this.getDate(),
    );
    const otherUTC = Date.UTC(
      otherDate.getFullYear(),
      otherDate.getMonth(),
      otherDate.getDate(),
    );
    const diffInMS = Math.abs(thisUTC - otherUTC);
    return Math.floor(diffInMS / (1000 * 60 * 60 * 24));
  }

  totalDays(otherDate: Date) {
    const startUTC = Date.UTC(
      this.getFullYear(),
      this.getMonth(),
      this.getDate(),
    );
    const endUTC = Date.UTC(
      otherDate.getFullYear(),
      otherDate.getMonth(),
      otherDate.getDate(),
    );
    const diffInMS = Math.abs(endUTC - startUTC);
    return Math.ceil(diffInMS / (1000 * 60 * 60 * 24)) + 1; // Including both start and end date
  }

  daysAndHoursLeft(): string {
    const currentDate = new Date();
    const timeDifference = this.getTime() - currentDate.getTime();
    const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    return `${daysLeft} days and ${hoursLeft} hours left`;
  }

  formatDate(): string {
    const date = this.handleTimeZone();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().slice(-2);
    return `${month}/${day}/${year}`;
  }

  isDateExpired(): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.getTime() <= today.getTime();
  }

  isDateInRange(startDate: Date, endDate: Date): boolean {
    const dateToCheck = this.getTime();
    const startDateTime = startDate.getTime();
    const endDateTime = endDate.getTime();
    return dateToCheck >= startDateTime && dateToCheck <= endDateTime;
  }

  setTimeToMidnight(): DateTimeZ {
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    this.setMilliseconds(0);
    return this;
  }

  defaultPayloadStringFormat() {
    const year = this.getFullYear();
    const month = String(this.getMonth() + 1).padStart(2, '0');
    const day = String(this.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  setTimeToEndOfDay(): DateTimeZ {
    this.setHours(23);
    this.setMinutes(59);
    this.setSeconds(59);
    this.setMilliseconds(999);
    return this;
  }

  addMonths(months: number): DateTimeZ {
    let date = new DateTimeZ(this.setMonth(this.getMonth() + 6));
    return date;
  }

  addDays(days: number): Date {
    const result = new Date(this);
    result.setDate(result.getDate() + days);
    return result;
  }
}

export default DateTimeZ;
