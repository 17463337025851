import React from 'react';

import { FaqProps } from './Faq.props';

import FaqView from './Faq.view';

const FaqContainer = (props: FaqProps) => {
  return <FaqView />;
};

export default FaqContainer;
